@import "design-system/common/variables";

#design-system {
  // Foundation
  @import "design-system/_foundation/foundation";

  @include foundation-flex-grid;
  @include foundation-flex-classes;
  @include foundation-visibility-classes;

  // Variables
  @import "design-system/common/colors";

  // Common
  @import "design-system/common/base";
  @import "design-system/common/accessibility";
  @import "design-system/common/typography";
  @import "design-system/common/mixins";

  // Utilities
  @import "design-system/utilities/utilities";
  @import "design-system/utilities/general";
  
  // Form
  @import "design-system/modules/form-elements.scss";
  @import "design-system/components-ds/form-paperless/form-paperless.scss";

  // Components DS
  @import "design-system/components-ds/button";
  @import "design-system/components-ds/buttons-group";
  @import "design-system/components-ds/disclaimer";
  @import "design-system/components-ds/header";
  @import "design-system/components-ds/info-box";
  @import "design-system/components-ds/list-simple";
  @import "design-system/components-ds/text-simple";
  @import "design-system/components-ds/arrow-link";

  background-color: $color-off-white;
  color: $color-grey-dark;
  font-family: $nndagny-text-regular;
  font-size: 16px;
  line-height: 1.25;
  text-align: left;

  .row {
    max-width: 93.5rem;
  }

  .columns {
    @media (min-width: 1365px) {
      padding-right: 2.040625rem;
      padding-left: 2.040625rem;
    }
  }

  .form-page-template {
    display: flex;
    background-color: $color-snow-white;
    // overflow: hidden;
    z-index: 1;
  
    .page-side-wrapper {
      width: calc(340px + calc((100vw - 1280px) / 2));
      min-width: 340px;
      align-self: flex-start;
  
      .side-inner-fixed {
        width: calc(340px + calc((100vw - 1280px) / 2));
        min-width: 340px;
        position: sticky;
        padding-top: 16px;
        padding-left: 48px;
  
        .logo {
          width: 156px;
        }
    
        .bg-wrapper {
          padding: 96px 0 0;


          img {
            margin-top: auto;
            width: 330px;
            display: block;
            margin-right: -57px;  
            margin-left: auto;
          }
        }
      }
    }
  
    .page-content-wrapper {
      flex: 1 1;
      min-height: 100vh;
      position: relative;
      background-color: $color-white;
    }
  }
}

html.modal-opened {
  overflow: hidden !important;

  body {
    overflow: hidden !important;
  }
}
main {
  position: relative;
  z-index: 1;
  border-bottom: 2px solid #EE7F00;
  margin-bottom: -1px;
}

.pre-footer-fixed {
  height: 2px;
  margin-bottom: 414px;
  z-index: 1;
  position: relative;

  @media (min-width: 400px) {
    margin-bottom: 414px;
  }

  @media (min-width: 640px) {
    margin-bottom: 254px;
  }
  
  @media (min-width: 740px) {
    margin-bottom: 234px;
  }


  @media (min-width: 790px) {
    margin-bottom: 214px;
  }

  
  @media (min-width: 1180px) {
    margin-bottom: 184px;
  }


}

.footer-fixed-wrapper {
  width: 100%;
  position: fixed;
  z-index: 0;
  bottom: 0;
}

footer.footer-lp {
  border-top: none !important;

  .row {
    max-width: 100%;

    @media screen and (min-width: 100em)  {
      padding: 0 3rem;
    }
  }

  a {
    font-family: NNNittiGrotesk-TextRegular;
  }

  .link.arrowed {
    color: #414141;
    font-family: NNNittiGrotesk-TextRegular;
    font-weight: 700;
    font-size: 1rem;
  }
}

@import "design-system/layout/footer-lp";
@import "design-system/layout/navigation-lp";